<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.6797 6.54761C20.6797 6.76443 20.5188 7.03128 20.34 7.19807C19.6966 7.79849 19.0531 8.38223 18.3559 8.93261C17.9985 9.21614 17.927 9.53303 17.927 9.93331C18.0122 13.5128 18.463 15.7455 19.4352 19.0849C19.5067 19.3305 19.3233 19.5775 19.0675 19.5775H15.6689C15.5295 12.3143 7.84675 12.3143 7.84675 19.5775H4.40806C4.16477 19.5775 3.98329 19.3534 4.03398 19.1155C4.68272 16.0699 5.33501 13.0498 5.55767 9.93331C5.57555 9.71649 5.41467 9.21614 5.2538 9.04936C4.61031 8.43226 3.89533 7.88188 3.25184 7.26478C3.03734 7.04796 2.84072 6.7144 2.84072 6.41419C2.80497 4.4795 2.82284 3.78528 2.82284 1.83392C2.82284 1.20014 3.03734 1 3.6987 1H5.52192C6.14754 1 6.36203 1.20014 6.37991 1.80056C6.39778 2.50105 6.37991 3.18486 6.37991 3.90203H9.07899C9.07899 3.20154 9.07899 2.51773 9.07899 1.85059C9.09686 1.20014 9.29348 1.01668 10.0085 1.01668H13.5477C14.209 1.01668 14.4414 1.21682 14.4414 1.83392C14.4593 2.51773 14.4414 3.18486 14.4414 3.90203H17.1405C17.1405 3.21822 17.1405 2.55108 17.1405 1.90063C17.1405 1.16678 17.3192 1.01668 18.1236 1.01668H19.8932C20.4294 1.01668 20.6975 1.26685 20.6975 1.75052C20.6975 3.7686 20.6975 4.52954 20.6797 6.54761Z"
    />
    <path
      d="M20.5585 23H3.35246C2.55621 23 1.89844 22.4945 1.89844 21.8826C1.89844 21.2706 2.55621 20.7651 3.35246 20.7651H20.5585C21.3547 20.7651 22.0125 21.2706 22.0125 21.8826C22.0125 22.4945 21.3547 23 20.5585 23Z"
    />
  </svg>
</template>
